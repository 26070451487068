import React from 'react';
import { StyleProp, Text as NativePaper, TextStyle } from 'react-native';

type TextProp = {
  size?: 'xxs' | 'xs' | 'md' | 'lg' | 'xl' | 'xxl',
  weight?: 300 | 600 | 400 | 500 | 700,
  native?: boolean,
  children: React.ReactNode,
  style?: StyleProp<TextStyle> | undefined
};

export default function Text({ size = 'md', weight = 500, children, style }: TextProp) {
  const sizes = {
    'xxs': { fontSize: 10, lineHeight: 14 },
    'xs': { fontSize: 14, lineHeight: 17 },
    'md': { fontSize: 16, lineHeight: 18 },
    'lg': { fontSize: 18, lineHeight: 20 },
    'xl': { fontSize: 22, lineHeight: 26 },
    'xxl': { fontSize: 26, lineHeight: 30 }
  }

  const weights = {
    300: 'Quicksand_300Light',
    600: 'Quicksand_600SemiBold',
    400: 'Quicksand_400Regular',
    500: 'Quicksand_500Medium',
    700: 'Quicksand_700Bold'
  }
  
  const customStyle = {
    ...sizes[size],
    ...style as Record<string, unknown>,
    fontFamily: weights[weight]
  };

  return <NativePaper style={customStyle} numberOfLines={999}>{children}</NativePaper>
}
