import { Animated, Easing } from "react-native";
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';

function runAnimation(spinValue: Animated.Value) {
  Animated.loop(
    Animated.timing(
      spinValue,
      {
        toValue: 1,
        duration: 1000,
        easing: Easing.linear,
        useNativeDriver: false 
      }
    )
  ).start(() => runAnimation(spinValue));
}


export default function Spinner({ color, size }: { color?: string, size?: number }) {
  const spinValue = new Animated.Value(0);

  runAnimation(spinValue);

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg']
  });

  return (
    <Animated.View style={{ transform: [{ rotate: spin }] }}>
      <FontAwesome5 name='circle-notch' size={size ?? 20} style={{ color: color ?? '#fff' }}></FontAwesome5>
    </Animated.View>
  )
}