import { Text } from "@core/components";
import styled from "styled-components/native";
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { useAxios } from "@core/hooks";
import { API_URL, User } from "@core/models";
import { useContext, useEffect, useState } from "react";
import { SafeAreaView, ScrollView } from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';
import { StackScreenProps } from "@react-navigation/stack";
import { RootStackScreenList } from "Routes";
import Spinner from "@core/components/spinner/Spinner";
import { SnackBarContext } from "contexts/SnackBarContext";

type UsersScreenProps = StackScreenProps<
  RootStackScreenList,
  'Users'
>;

export default function Users({ navigation }: UsersScreenProps) {
  const [userToDelete, setUser] = useState<User>();
  const { setSnackBar } = useContext(SnackBarContext);

  const { response: users, error: usersError, fetchData: getUsers, loading } = useAxios<User[]>({
    url: `${API_URL}/customers`,
    method: 'get'
  });
  const { error: deleteError, response: deleteResponse, fetchData: callDeleteUser, loading: deleteLoading } = useAxios<User[]>({
    url: `${API_URL}/customers/${userToDelete?._id}`,
    method: 'delete'
  });

  useEffect(() => {
    const unsubscribeFocus = navigation.addListener('focus', () => {
      getUsers();
      setUser(undefined);
    });

    return unsubscribeFocus;
  }, [navigation]);
  
  useEffect(() => {
    if (deleteResponse !== undefined) {
      getUsers();
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (userToDelete) {
      callDeleteUser();
    }
  }, [userToDelete]);

  useEffect(() => {
    if (deleteError) {
      setSnackBar({ visible: true, message: 'Erro ao tentar apagar o cliente. Por favor, tente novamente mais tarde.' });
    }
  }, [deleteError]);

  useEffect(() => {
    if (usersError) {
      setSnackBar({ visible: true, message: 'Erro ao tentar buscar os clientes. Por favor, tente novamente mais tarde.' });
    }
  }, [usersError]);

  const deleteUser = (user: User) => {
    setUser(user);
  }

  return (
    <SafeAreaView>
      <UsersView>
        <TitleView>
          <Ionicons name='person-outline' size={24} />
          <Text size='xl' weight={700}>Clientes</Text>
        </TitleView>

        <Ionicons.Button
          backgroundColor='#3f66df'
          name='person-add-outline'
          onPress={() => navigation.push('AddUser')}
        >Adicionar</Ionicons.Button>
      </UsersView>
      <ScrollView style={{ paddingLeft: 16, paddingRight: 16, maxHeight: 'calc(100vh - 125px)' }}>
        {loading && <Text>Carregando...</Text>}
        {users?.map(user => (
          <RowView id={user._id} key={user._id}>
            <RowInfoView>
              <TitleView>
                <Text>{user.name}</Text>
                {user.isAdmin ? <MaterialIcons name="admin-panel-settings" size={16} /> : <></>}
              </TitleView>
              <Text size='xs' weight={400}>{user.email}</Text>
              <Text size='xxs'>{user.phone}</Text>
            </RowInfoView>
            <ButtonsView>
              {deleteLoading && user._id === userToDelete?._id ?
                <Spinner color='#161616' /> :
                <MaterialIcons onPress={() => deleteUser(user)} name="delete-outline" size={20} />
              }
            </ButtonsView>
          </RowView>
        ))}
      </ScrollView>
    </SafeAreaView>
  )
}

const UsersView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

const TitleView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
`;

const RowView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: center;
  column-gap: 4px;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-color: #cccccc;
  padding: 8px 0px;
  width: 100%;
`;

const RowInfoView = styled.View`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  flex: 1;
`;

const ButtonsView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  row-gap: 4px;
`;