import { LoggedUserContext } from 'contexts/LoggedUserContext';
import * as WebBrowser from 'expo-web-browser';
import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { RootStackScreenList } from 'Routes';

import { Header } from '@core/components';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import Home from 'pages/home/Home';
import Login from 'pages/login/Login';
import { useCachedResources } from '@core/hooks';
import AdminHome from 'pages/admin-home/AdminHome';
import Users from 'pages/users/Users';
import AddUser from 'pages/users/add/AddUser';
import Requests from 'pages/requests/Requests';
import GateLogs from 'pages/gate-log/GateLog';
import { Snackbar } from 'react-native-paper';
import { SnackBarContext } from 'contexts/SnackBarContext';
import DeleteAccount from 'pages/delete-account/DeleteAccount';
import Download from 'pages/download/Download';
import DownloadInstructions from 'pages/download-instructions/DownloadInstructions';
// Allows auto-close login popup
WebBrowser.maybeCompleteAuthSession();

const RootStack = createStackNavigator<RootStackScreenList>();

const linking = {
  prefixes: ['estacionamentomagalhaes://', 'https://www.estacionamentomagalhaes.com.br'],
  config: {
    screens: {
      Login: 'login',
      Home: 'home',
      AdminHome: 'admin-home',
      Users: 'users',
      AddUser: 'users/add',
      Requests: 'requests',
      GateLogs: 'gate-logs',
      DeleteAccount: 'delete-account',
      Download: 'download',
      DownloadInstructions: 'download-instructions',
    },
  },
};

export const notHeaderPages = ['Login', 'DeleteAccount', 'Download'];

export default function ProtectedPages() {
  const { user } = useContext(LoggedUserContext);
  const { visible, time, message, setSnackBar } = useContext(SnackBarContext);

  const { isLoadingComplete } = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  }

  return (
    <SafeAreaProvider style={styles.container}>
      <NavigationContainer linking={linking}>
        <RootStack.Navigator
          screenOptions={{ header: (props) => (user || props.route.name !== 'Login' ? <Header {...props} /> : <></>) }}
        >
          {user ? (
            user.isAdmin ?
            (<>
              <RootStack.Screen name="AdminHome" component={AdminHome} />
              <RootStack.Screen name="Users" component={Users} />
              <RootStack.Screen name="Requests" component={Requests} />
              <RootStack.Screen name="GateLogs" component={GateLogs} />
            </>):
              <RootStack.Screen
                name="Home"
                component={Home}
              />
          ) : (
            <RootStack.Screen name="Login" component={Login} />
          )}
          <RootStack.Screen name="AddUser" component={AddUser} />
          <RootStack.Screen name="DeleteAccount" component={DeleteAccount} />
          <RootStack.Screen name="Download" component={Download} />
          <RootStack.Screen name="DownloadInstructions" component={DownloadInstructions} />
        </RootStack.Navigator>
      </NavigationContainer>

      <Snackbar
        visible={visible ?? false}
        onDismiss={() => setSnackBar({ visible: false, message: '', time: 3000 })}
        duration={time}
      >{message}</Snackbar>
    </SafeAreaProvider>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F9FAFC'
  }
});