import React, { createContext, useState } from 'react';

import { LoggedUser } from '@core/models/LoggedUser.model';

interface LoggedUserContextProps extends LoggedUser {
  setLoggedUser: (loggedUser?: Partial<LoggedUser>) => void;
}

export const LoggedUserContext = createContext<LoggedUserContextProps>({} as LoggedUserContextProps);

export default function LoggedUserProvider({ children }: { children: React.ReactNode }) {
  const [loggedUser, setLoggedUserContext] = useState<LoggedUser>({} as LoggedUser);

  const setLoggedUser = (newLoggedUser?: Partial<LoggedUser>) => {
    if (newLoggedUser) {
      if (newLoggedUser.user) {
        newLoggedUser.user.isAdmin = newLoggedUser.user.rules === 'ADMIN';
      }

      setLoggedUserContext({
        ...loggedUser,
        ...newLoggedUser,
      });
    }
  }

  return <LoggedUserContext.Provider value={{ ...loggedUser, setLoggedUser }}>{ children }</LoggedUserContext.Provider>
}