import { LoggedUserContext } from 'contexts/LoggedUserContext';
import React, { useContext } from 'react';
import styled from 'styled-components/native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';

import Text from '../text/Text';
import { SafeAreaView, StatusBar } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StackHeaderProps } from '@react-navigation/stack';
import { notHeaderPages } from '../../../ProtectedPages';

export default function Header({ navigation, route }: StackHeaderProps) {
  const { setLoggedUser } = useContext(LoggedUserContext);

  const logout = () => {
    AsyncStorage.removeItem('estacioMagaCredentials');
    setLoggedUser({ user: undefined });
  };

  return (
    <SafeAreaView>
      <HeaderView>
        <TitleView>
          <StatusBar translucent={false} backgroundColor="#161616" />
          {navigation.canGoBack() ?
            <MaterialCommunityIcons name="arrow-left" color={'#fff'} size={18} onPress={() => navigation.goBack()}></MaterialCommunityIcons> :
            <></>
          }
          <Text size='lg' style={{ color: '#fff' }}>Estacionamento Magalhães</Text>
        </TitleView>
        {
          !notHeaderPages.includes(route.name) ?
            <MaterialIcons name="logout" color={'#fff'} size={18} onPress={logout}></MaterialIcons> :
            <></>
        }
      </HeaderView>
    </SafeAreaView>
  );
}

const HeaderView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: #161616;
`;

const TitleView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
`;