import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { useContext, useEffect, useState } from 'react';
import { useFonts, Quicksand_600SemiBold, Quicksand_400Regular, Quicksand_300Light, Quicksand_500Medium, Quicksand_700Bold } from '@expo-google-fonts/quicksand';

import { MaterialIcons, FontAwesome5 } from '@expo/vector-icons';

import { LoggedUserContext } from 'contexts/LoggedUserContext';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const [fontsLoaded] = useFonts({
    Quicksand_600SemiBold,
    Quicksand_400Regular,
    Quicksand_300Light,
    Quicksand_500Medium,
    Quicksand_700Bold
  });

  const { setLoggedUser } = useContext(LoggedUserContext);

  const checkLoginCredentials = () => {
    AsyncStorage
      .getItem('estacioMagaCredentials')
      .then((result) => {
        if (result !== null) {
          setLoggedUser({ user: JSON.parse(result) });
        } else {
          setLoggedUser({ user: undefined });
        }
      })
      .catch(error => console.warn(error))
  };

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        checkLoginCredentials();

        // Load fonts
        await Font.loadAsync({
          ...FontAwesome5.font,
          ...MaterialIcons.font,
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, [fontsLoaded]);

  return { isLoadingComplete };
}
