import { SnackBarOption } from '@core/models/SnackBar';
import React, { createContext, useState } from 'react';

interface SnackBarContextProps extends SnackBarOption {
  setSnackBar: (snackBar: SnackBarOption) => void;
}

export const SnackBarContext = createContext<SnackBarContextProps>({} as SnackBarContextProps);

export default function SnackBarProvider({ children }: { children: React.ReactNode }) {
  const [snackBar, setSnackBarContext] = useState<SnackBarOption>({ message: '', visible: false, time: 3000 } as SnackBarOption);

  const setSnackBar = (newSnackBar: SnackBarOption) => {
    setSnackBarContext({
      ...snackBar,
      ...newSnackBar,
    });
  }

  return <SnackBarContext.Provider value={{ ...snackBar, setSnackBar }}>{ children }</SnackBarContext.Provider>
}