import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { useState } from 'react';

export type AxiosMethod = "get" | "post" | "put" | "patch" | "delete";

const useAxios = <T>({
  url,
  method,
  body,
  headers,
  params
}: {
  url: string;
  method: AxiosMethod;
  body?: Record<string, unknown>;
  headers?: AxiosRequestHeaders;
  params?: Record<string, unknown>
}) => {
  const [response, setResponse] = useState<T>();
  const [error, setError] = useState<AxiosError>();
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);

    const fullHeaders = headers ?? {};

    const axiosPromise =
      method === "get" || method === "delete"
        ? axios[method](url, { headers: fullHeaders, params })
        : axios[method](url, body, { headers: fullHeaders });

    axiosPromise
      .then((response: AxiosResponse<T>) => {
        console.log("🚀 ~ file: useAxios.ts:35 ~ response:", response)
        setResponse(response.data as T);
      })
      .catch((error: AxiosError) => {
        console.warn("[Axios]", url, "🚀 ~ fetchData ~ error", JSON.stringify(error));
        setError(error);
      })
      .finally(() => setLoading(false));
  };

  return { response, error, loading, fetchData };
};

export default useAxios;
