import { Text } from "@core/components";
import { SafeAreaView, StyleProp, ViewStyle } from "react-native";
import styled from "styled-components/native";
import Ionicons from '@expo/vector-icons/Ionicons';
import { useContext, useEffect, useState } from "react";
import { LoggedUserContext } from "contexts/LoggedUserContext";
import { RadioButton } from 'react-native-paper';
import { useAxios } from "@core/hooks";
import { API_URL } from "@core/models";
import { RootStackScreenList } from "Routes";
import { StackScreenProps } from "@react-navigation/stack";
import Spinner from "@core/components/spinner/Spinner";
import InfoUser from "../info-user/InfoUser";
import { SnackBarContext } from "contexts/SnackBarContext";

type AddUserScreenProps = StackScreenProps<
  RootStackScreenList,
  'AddUser'
>;

export default function AddUser({ navigation }: AddUserScreenProps) {
  const { user: loggedUser } = useContext(LoggedUserContext);
  const { setSnackBar } = useContext(SnackBarContext);
  const [disabledStyle, setDisabledStyle] = useState<StyleProp<ViewStyle>>({ opacity: 0.5 });
  const [user, onChangeUserForm] = useState({
    name: '',
    email: '',
    phone: '',
    admin: false
  });
  const { response: toggleResponse, error: toggleError, fetchData: saveUser, loading } = useAxios({
    url: `${API_URL}/${loggedUser?.isAdmin ? 'customers' : 'requests'}`,
    method: 'post',
    body: {
      name: user.name,
      email: user.email,
      phone: user.phone,
      password: `estaciomaga${user.phone.slice(-4)}`,
      rules: user.admin ? 'ADMIN' : 'CUSTOMER'
    }
  });

  useEffect(() => {
    setDisabledStyle({
      opacity: !user.name || !user.email || !user.phone ? 0.5 : 1
    });
  }, [user]);

  useEffect(() => {
    if (toggleResponse && loggedUser?.isAdmin) {
      navigation.navigate('Users');
      setSnackBar({ visible: true, message: 'Cliente cadastrado com sucesso!' });

    }
  }, [toggleResponse]);

  useEffect(() => {
    if (toggleError && loggedUser?.isAdmin) {
      setSnackBar({ visible: true, message: 'Erro ao cadastrar o cliente!' });
    }
  }, [toggleError]);

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <UsersView>
        <TitleView>
          <Ionicons name='person-add-outline' size={24} />
          <Text size='xl' weight={700}>{loggedUser?.isAdmin ? 'Adicionar Cliente' : 'Nova Conta'}</Text>
        </TitleView>
      </UsersView>

      <AddUserView>
        {toggleResponse && !loggedUser?.isAdmin  ?
          <InfoUser email={user.email} password={`estaciomaga${user.phone.slice(-4)}`} /> :
          <FormView>
            {loggedUser?.isAdmin ?
              <></> :
              <Text size='xl' weight={700}>Bem vindo! Cadastre suas informações para ter acesso.</Text>
            }
            <TextInput
              placeholder={'Nome'}
              keyboardType={'default'}
              onChangeText={(name) => onChangeUserForm({ ...user, name })}
              value={user.name}
            />

            <TextInput
              placeholder={'Email'}
              keyboardType={'email-address'}
              onChangeText={(email) => onChangeUserForm({ ...user, email: email?.toLowerCase() })}
              value={user.email}
            />

            <TextInput
              placeholder={'Telefone'}
              keyboardType={'numeric'}  
              onChangeText={(phone) => onChangeUserForm({ ...user, phone })}
              value={user.phone}
            />

            {loggedUser?.isAdmin ?
              <>
                <RadioButtonForm>
                  <RadioButton
                    value="admin"
                    color="#3f66df"
                    status={user.admin ? 'checked' : 'unchecked'}
                    onPress={() => onChangeUserForm({ ...user, admin: true })}
                  />
                  <Text size='md'>Administrador</Text>
                  <RadioButton
                    value="customer"
                    color="#3f66df"
                    status={!user.admin ? 'checked' : 'unchecked'}
                    onPress={() => onChangeUserForm({ ...user, admin: false })}
                  />
                  <Text size='md'>Cliente</Text>
                </RadioButtonForm>
              </>
              : <></>}

          </FormView>
        }
        <ButtonsView>
          {toggleResponse ?
            <></> :
            <ButtonSave
              style={[disabledStyle]}
              disabled={!user.name || !user.email || !user.phone || loading}
              onPress={saveUser}
            >
              {loading ?
                <Spinner /> :
                <Text style={{ color: '#ffffff' }}>Salvar</Text>
              }
            </ButtonSave>
          }

          <ButtonClear
            style={{ backgroundColor: '#161616' }}
            onPress={() => navigation.goBack()}>
            <Text style={{ color: '#ffffff' }}>{loggedUser?.isAdmin ? 'Voltar' : 'Fazer login'}</Text>
          </ButtonClear>
        </ButtonsView>
      </AddUserView>
    </SafeAreaView>
  )
}

const AddUserView = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex: 1;
  flex-wrap: wrap;
`;

const UsersView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
`;

const TitleView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
`;

const RadioButtonForm = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
  width: 100%;
`;

const FormView = styled.View`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  row-gap: 12px;
  width: 100%;
`;

const ButtonsView = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  row-gap: 4px;
  width: 100%;
`;

const TextInput = styled.TextInput`
  border: 1px solid #161616;
  padding: 12px;
  border-radius: 6px;
  font-family: Quicksand_400Regular;
  width: 100%;
`;

const ButtonSave = styled.TouchableOpacity`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  width: 100%;  
  background-color: #3f66df;
`;

const ButtonClear = styled.TouchableOpacity`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  width: 100%;
  background-color: #161616;
`;