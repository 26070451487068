// import React from 'react';
import { Image, Linking, SafeAreaView, ScrollView, TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';
import type { StackScreenProps } from '@react-navigation/stack';
import { RootStackScreenList } from 'Routes';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import { Text } from '@core/components';
import { manufacturer } from 'expo-device';

export default function Download({ navigation }: StackScreenProps<RootStackScreenList, 'Download'>) {
  return (
    <SafeAreaView>
      <ScrollView>
        <InformationView>
          <LogoImage style={{ marginBottom: 20, marginTop: manufacturer === 'Apple' ? 40 : 140 }} source={require('@assets/icon.png')} />
          {
            manufacturer === 'Apple' ?
              <>
                <Text size={'lg'} style={{ marginBottom: 5 }}>Bem vindo ao <b>Estacionamento Magalhães</b>, aqui você pode utilizar o portão automático pelo seu celular. Inicie indo para a página inicial e clique em "Criar conta".</Text>
                <Text size={'lg'} style={{ marginBottom: 30 }}>Mas antes, você pode utilizar este site como um aplicativo no seu iPhone, se quiser saber como, clique em <b>"Instalar aplicativo"</b>, se não, clique em "Ir para o ínicio".</Text>
                <FontAwesome5.Button
                  name="link"
                  size={12}
                  backgroundColor="#161616"
                  onPress={() => navigation.push('DownloadInstructions')}
                >
                  <Text size='md' style={{ color: '#fff' }}>Instalar aplicativo</Text>
                </FontAwesome5.Button>
                <View style={{ marginBottom: 10 }}></View>
                <FontAwesome5.Button
                  name="link"
                  size={12}
                  onPress={() => Linking.openURL('https://estacionamentomagalhaes.com.br/login')}>
                  <Text size='md' style={{ color: '#fff' }}>Ir para o ínicio</Text>
                </FontAwesome5.Button>
              </>
              : 
              <>
                <Text size={'xl'} style={{ marginBottom: 20 }}>Bem vindo ao <b>Estacionamento Magalhães</b>, faça o download do aplicativo para a abertura do portão automático.</Text>
                <FontAwesome5.Button
                  name="download"
                  size={16}
                  onPress={() => {
                    const link = document.createElement('a');
                    link.target = '_blank';
                    link.href = 'https://estacionamentomagalhaes.com.br/static/media/estacionamento-magalhaes.apk';
                    link.download = 'estacionament-magalhaes.apk';
                    link.click();
                  }}>
                  <Text size='md' style={{ color: '#fff' }}>Baixar aplicativo</Text>
                </FontAwesome5.Button>
              </>
          }
        </InformationView>
      </ScrollView>
    </SafeAreaView>
  );
}

const LogoImage = styled.Image`
  border-width: 2px;
  border-color: #ffffff;
  border-radius: 100px;
  width: 100px;
  height: 100px;
`;

const InformationView = styled.View`
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
`;
