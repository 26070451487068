import LoggedUserProvider from 'contexts/LoggedUserContext';
import React from 'react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ProtectedPages from './ProtectedPages';
import SnackBarProvider from 'contexts/SnackBarContext';

export default function App() {
  return (
    <LoggedUserProvider>
      <SnackBarProvider>
        <ProtectedPages />
      </SnackBarProvider>
    </LoggedUserProvider>
  );
}

serviceWorkerRegistration.register();