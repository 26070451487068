import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/native';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import { useAxios } from '@core/hooks';
import { API_URL, GateStatus } from '@core/models';
import { LoggedUserContext } from 'contexts/LoggedUserContext';
import { StyleProp, ViewStyle, Vibration, Alert, Linking, Touchable, TouchableOpacity } from 'react-native';
import { SnackBarContext } from 'contexts/SnackBarContext';
import * as Location from 'expo-location';
import Spinner from '@core/components/spinner/Spinner';
import * as Device from 'expo-device';
import { Text } from 'react-native-paper';

function distanceBetweenParkingAndPerson({ latitude, longitude }: { latitude: number, longitude: number }) {
  const earthRadius = 6371.0710;
  const latitudeParking = -27.5967424;
  const longitudeParking = -48.5448513;
  const radiansLatitudePerson = latitude * (Math.PI/180);
  const radiansLatitudeParking = latitudeParking * (Math.PI/180);
  const differenceLatitude = radiansLatitudeParking-radiansLatitudePerson;
  const differenceLongitude = (longitude-longitudeParking) * (Math.PI/180);

  const distance = 2 * earthRadius * Math.asin(
    Math.sqrt(
      Math.sin(differenceLatitude/2) * 
      Math.sin(differenceLatitude/2) + 
      Math.cos(radiansLatitudePerson) * 
      Math.cos(radiansLatitudeParking) * 
      Math.sin(differenceLongitude/2) * 
      Math.sin(differenceLongitude/2)
    )
  );

  return distance;
}

export default function Home() {
  const { user } = useContext(LoggedUserContext);
  const [isLoadingGate, setLoadingGate] = useState(false);
  const [disabledStyle, setDisabledStyle] = useState<StyleProp<ViewStyle>>({ opacity: 0.5 });
  const [distance, setDistance] = useState<number>(0);
  const [_, requestPermission] = Location.useForegroundPermissions();

  const { setSnackBar } = useContext(SnackBarContext);
  const { response: toggleResponse, error: toggleError, fetchData: toggleDevice } = useAxios({
    url: `${API_URL}/ewelink/toggle`,
    method: 'get',
    params: { email: user?.email, device: Device.modelName }
  });
  const { response: gateStatus, error: gateStatusError, fetchData: getGateStatus } = useAxios<GateStatus>({
    url: `${API_URL}/ewelink`,
    method: 'get'
  });

  useEffect(() => {
    async function getLocation() {
      const { coords } = await Location.getCurrentPositionAsync({});
      const distance = distanceBetweenParkingAndPerson(coords);
      setDistance(distance);

      if (distance > 1) {
        setSnackBar({
          visible: true,
          message: 'Você está muito longe do portão, por favor, tente se aproximar ou avise o Alberto.'
        });
        setLoadingGate(false);
        return;
      }

      getGateStatus();
    }

    async function askLocation() {
      let { status, canAskAgain } = await requestPermission();

      if (status !== 'granted') {
        setLoadingGate(false);

        if (!canAskAgain) {
          Alert.alert(
            'Localização não concedida',
            'Precisamos da mesma para poder saber se você está próximo do Estacionamento, para a segurança sua e do seu carro. Se você deseja mudar de ideia, vá as configurações do aplicativo e ative a localização.',
            [
              { text: 'Cancelar', style: 'cancel' },
              { text: 'Configurações de localização', onPress: () => {
                Linking.sendIntent('android.settings.LOCATION_SOURCE_SETTINGS')
              } },
            ]
          );
        } else {
          Alert.alert(
            'Localização não concedida',
            'Você recusou o uso da localização, precisamos da mesma para poder saber se você está próximo do Estacionamento, para a segurança sua e do seu carro.',
            [
              { text: 'Cancelar', style: 'cancel' },
              { text: 'Dar permissão', onPress: askLocation },
            ]
          );
        }

        console.warn('Permission to access location was denied');
        return;
      } else {
        getLocation();
      }
    }

    setLoadingGate(true);
    askLocation();
  }, []);
 
  const activateGate = () => {
    Vibration.vibrate(1.5 * 1000)
    toggleDevice();
    setLoadingGate(true);
  };

  useEffect(() => {
    if (toggleResponse || toggleError) {
      setLoadingGate(false);
    }
  }, [toggleResponse, toggleError]);

  useEffect(() => {
    if (distance > 0.0001 && distance < 1) {
      setLoadingGate(false);

      if (!!gateStatusError) {
        setSnackBar({ visible: true, message: 'O portão está desligado. Por favor, tente novamente mais tarde.' });
        setDisabledStyle({ opacity: 0.5 });
      }
  
      if (gateStatus?.status) {
        setDisabledStyle({ opacity: 1 });
      }
    }
  }, [gateStatus, gateStatusError]);

  return (
    <ContainerView>
      <InformationView>
        <LogoImage style={{ marginBottom: 40 }} source={require('@assets/icon.png')} />
        <PowerButton
          style={[disabledStyle]}
          disabled={!!gateStatusError || distance > 1}
          onPress={activateGate}
        >
          {isLoadingGate ?
            <Spinner size={40} /> :
            <FontAwesome5 name='power-off' size={40} style={{ color: '#fff' }}></FontAwesome5>
          }
        </PowerButton>
      </InformationView>
      <TouchableOpacity style={{ display: 'flex', alignItems: 'center' }} onPress={() => Linking.openURL('https://wa.me/5548991062660?text=Ol%C3%A1%20Alberto')}>
        <Text style={{ marginTop: 20 }}>Algum problema? Contate o Alberto em</Text>
        <Text style={{ color: '#1d69be'  }}>(48) 99106-2660</Text>
      </TouchableOpacity>
    </ContainerView>
  );
}

const ContainerView = styled.View`
  flex: 1 1 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const InformationView = styled.View`
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoImage = styled.Image`
  width: 130px;
  height: 130px;
`;

const PowerButton = styled.TouchableOpacity`
  width: 130px;
  height: 130px;
  background-color: #161616;
  border-radius: 500px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
