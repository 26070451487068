import React from 'react';
import { Linking, SafeAreaView } from 'react-native';
import styled from 'styled-components/native';
import type { StackScreenProps } from '@react-navigation/stack';
import { RootStackScreenList } from 'Routes';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import { Text } from '@core/components';

export default function DeleteAccount({}: StackScreenProps<RootStackScreenList, 'DeleteAccount'>) {
  return (
    <SafeAreaView style={{ height: '100%' }}>
      <ContainerView>
        <InformationView>
          <LogoImage style={{ marginBottom: 40 }} source={require('@assets/icon.png')} />
          <Text style={{ marginBottom: 20 }}>Você deseja excluir a sua conta? <br/>Sem problemas, você deve enviar uma mensagem para o Alberto via WhatsApp no número:</Text>
          <FontAwesome5.Button
            name="whatsapp"
            onPress={() => Linking.openURL('https://wa.me/5548991062660?text=Ol%C3%A1%20Alberto,%20gostaria%20de%20excluir%20a%20minha%20conta%20no%20aplicativo,%20o%20meu%20email%20%C3%A9:')}>
            <Text style={{ color: '#fff' }}>(48) 99106-2660</Text>
          </FontAwesome5.Button>
        </InformationView>
      </ContainerView>
    </SafeAreaView>
  );
}

const LogoImage = styled.Image`
  border-width: 2px;
  border-color: #ffffff;
  border-radius: 100px;
  width: 130px;
  height: 130px;
`;
const ContainerView = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  background: white;
`;

const InformationView = styled.View`
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
`;
