
import { Image, SafeAreaView, ScrollView, TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';
import type { StackScreenProps } from '@react-navigation/stack';
import { RootStackScreenList } from 'Routes';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import { Text } from '@core/components';
import { useState } from 'react';

const images = [
  require('@assets/ios_1.png'),
  require('@assets/ios_2.png'),
  require('@assets/ios_3.png'),
];

export default function DownloadInstructions({}: StackScreenProps<RootStackScreenList, 'DownloadInstructions'>) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  
  return (
    <SafeAreaView>
      <ScrollView>
        <InformationView>
          <Text size={'lg'} style={{ marginBottom: 5, marginTop: 40 }}>Se desejar utilizar o site como aplicativo, abra-o com o navegador <b>Safari</b>, na página <a href="https://estacionamentomagalhaes.com.br/login">https://estacionamentomagalhaes.com.br/login</a> e siga as instruções das imagens abaixo.</Text>
          <View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 20 }}>
            <TouchableOpacity onPress={handlePrev} disabled={currentIndex === 0}>
              <FontAwesome5 name="arrow-left" size={24} color={currentIndex === 0 ? 'grey' : 'black'} />
            </TouchableOpacity>
            <TouchableOpacity onPress={handleNext} disabled={currentIndex === images.length - 1} style={{ marginLeft: 20 }}>
              <FontAwesome5 name="arrow-right" size={24} color={currentIndex === images.length - 1 ? 'grey' : 'black'} />
            </TouchableOpacity>
          </View>
          <View style={{ flexDirection: 'row', marginBottom: 20 }}>
            {images.map((image, index) => (
              index === currentIndex ?
                <Image key={index} style={{ width: 250, height: 500, marginRight: 10 }} source={image} />
                : null
            ))}
          </View>
        </InformationView>
      </ScrollView>
    </SafeAreaView>
  );
}

const InformationView = styled.View`
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
`;