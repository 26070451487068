import { Text } from "@core/components";
import styled from "styled-components/native";
import AntDesign from '@expo/vector-icons/AntDesign';
import { useAxios } from "@core/hooks";
import { API_URL, User } from "@core/models";
import { useEffect, useState } from "react";
import { SafeAreaView, ScrollView } from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';
import { StackScreenProps } from "@react-navigation/stack";
import { RootStackScreenList } from "Routes";
import Spinner from "@core/components/spinner/Spinner";

type RequestsScreenProps = StackScreenProps<
  RootStackScreenList,
  'Requests'
>;

export default function Requests({ navigation }: RequestsScreenProps) {
  const [request, setRequest] = useState<User>();
  const { response: requests, error: requestsError, fetchData: getRequests, loading: requestsLoading  } = useAxios<User[]>({
    url: `${API_URL}/requests`,
    method: 'get'
  });
  const { error: deleteError, fetchData: deleteRequest } = useAxios<User[]>({
    url: `${API_URL}/requests/${request?._id}`,
    method: 'delete'
  });
  const { error: saveError, response: saveResponse, fetchData: saveCustomer, loading } = useAxios<User[]>({
    url: `${API_URL}/customers`,
    method: 'post',
    body: {
      name: request?.name,
      email: request?.email,
      phone: request?.phone,
      password: `estaciomaga${request?.phone.slice(-4)}`,
      rules: 'CUSTOMER'
    }
  });

  useEffect(() => {
    getRequests();
  }, [saveResponse]);

  useEffect(() => {
    saveCustomer();
    deleteRequest();
  }, [request]);

  const acceptRequest = (request: User) => {
    setRequest(request);
  }

  return (
    <SafeAreaView style={{ paddingBottom: 65 }}>
      <RequestsView>
        <TitleView>
          <Ionicons name='person-outline' size={24} />
          <Text size='xl' weight={700}>Solicitações</Text>
        </TitleView>
      </RequestsView>
      <ScrollView style={{ paddingLeft: 16, paddingRight: 16 }}>
        {requestsLoading && <Text>Carregando...</Text>}
        {!requests?.length && !requestsLoading && <Text>Não há solicitações no momento</Text>}
        {requests?.map(requestFromList => (
          <RowView id={requestFromList._id} key={requestFromList._id}>
            <RowInfoView>
              <TitleView>
                <Text>{requestFromList.name}</Text>
              </TitleView>
              <Text size='xs' weight={400}>{requestFromList.email}</Text>
              <Text size='xxs'>{requestFromList.phone}</Text>
            </RowInfoView>
            <ButtonsView>
              {loading && requestFromList._id === request?._id  ?
                <Spinner color='#161616' /> :
                <AntDesign onPress={() => acceptRequest(requestFromList)} name="pluscircleo" size={25} />
              }
            </ButtonsView>
          </RowView>
        ))}
      </ScrollView>
    </SafeAreaView>
  )
}

const RequestsView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

const TitleView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
`;

const RowView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: center;
  column-gap: 4px;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-color: #cccccc;
  padding: 8px 0px;
  width: 100%;
`;

const RowInfoView = styled.View`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  flex: 1;
`;

const ButtonsView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  row-gap: 4px;
`;