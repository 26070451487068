import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/native';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import { Text } from '@core/components';
import { StyleProp, Vibration, ViewStyle } from 'react-native';
import { useAxios } from '@core/hooks';
import { API_URL, GateStatus } from '@core/models';
import { LoggedUserContext } from 'contexts/LoggedUserContext';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackScreenList } from 'Routes';
import Spinner from '@core/components/spinner/Spinner';
import { SnackBarContext } from 'contexts/SnackBarContext';
// import Print from '@core/components/print/Print';
import * as Device from 'expo-device';

type AdminHomeScreenProps = StackScreenProps<
  RootStackScreenList,
  'AdminHome'
>;

export default function AdminHome({ navigation }: AdminHomeScreenProps) {
  const { user } = useContext(LoggedUserContext);
  const [isLoadingGate, setLoadingGate] = useState(false);
  const { setSnackBar } = useContext(SnackBarContext);
  const [disabledStyle, setDisabledStyle] = useState<StyleProp<ViewStyle>>({ opacity: 0.5 });
  const { response: toggleResponse, error: toggleError, fetchData: toggleDevice } = useAxios({
    url: `${API_URL}/ewelink/toggle`,
    method: 'get',
    params: { email: user?.email, device: Device.modelName  }
  });
  const { response: gateStatus, error: gateStatusError, fetchData: getGateStatus } = useAxios<GateStatus>({
    url: `${API_URL}/ewelink`,
    method: 'get'
  });

  const activateGate = () => {
    Vibration.vibrate(1.5 * 1000)
    toggleDevice();
    setLoadingGate(true);
  };

  useEffect(() => {
    setLoadingGate(false);
  }, [toggleResponse, toggleError]);

  useEffect(() => {
    getGateStatus();
  }, []);

  useEffect(() => {
    if (!!gateStatusError) {
      setSnackBar({ visible: true, message: 'O portão está desligado. Por favor, tente novamente mais tarde.' });
      setDisabledStyle({ opacity: 0.5 });
    }

    if (gateStatus?.status) {
      setDisabledStyle({ opacity: 1 });
    }
  }, [gateStatus, gateStatusError]);

  return (
    <ContainerView>
      <ButtonView>
        <LoadingTouchable
          style={[disabledStyle]}
          disabled={!!gateStatusError}
          onPress={activateGate}
        >
          {isLoadingGate ?
            <Spinner /> :
            <FontAwesome5 name='power-off' size={20} style={{ color: '#fff' }}></FontAwesome5>
          }
          <Text style={{ color: '#ffffff', fontWeight: '600', marginLeft: 10 }}>Portão</Text>
        </LoadingTouchable>
      </ButtonView>
      <ButtonView>
        <FontAwesome5.Button onPress={() => navigation.push('Users')} name="users" backgroundColor={'#161616'}>
          <Text style={{ color: '#ffffff' }}>Clientes</Text>
        </FontAwesome5.Button>
      </ButtonView>
      <ButtonView>
        <FontAwesome5.Button onPress={() => navigation.push('Requests')} name="users" backgroundColor={'#161616'}>
          <Text style={{ color: '#ffffff' }}>Novas contas</Text>
        </FontAwesome5.Button>
      </ButtonView>
      <ButtonView>
        <FontAwesome5.Button onPress={() => navigation.push('GateLogs')} name="list-ul" backgroundColor={'#161616'}>
          <Text style={{ color: '#ffffff' }}>Acesso do Portão</Text>
        </FontAwesome5.Button>
      </ButtonView>

      {/* <ButtonView>
        <FontAwesome5.Button name="print" backgroundColor={'#161616'}>
          <Text style={{ color: '#ffffff' }}>Controle de impressões</Text>
        </FontAwesome5.Button>
      </ButtonView> */}

      {/* <Print /> */}
    </ContainerView>
  );
}

const ContainerView = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  height: 100%;
  width: 100%;
`;

const ButtonView = styled.View`
  width: 100%;
  margin-bottom: 16px;
`;

const LoadingTouchable = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
  background-color: #3f66df;
`;
