import { useAxios } from '@core/hooks';
import { API_URL, User } from '@core/models';
import { LoggedUserContext } from 'contexts/LoggedUserContext';
import React, { useContext, useEffect, useState } from 'react';
import { SafeAreaView } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import styled from 'styled-components/native';
import { Text } from '@core/components';
import type { StackScreenProps } from '@react-navigation/stack';
import { RootStackScreenList } from 'Routes';
import { TextInput } from 'react-native-paper';
import Spinner from '@core/components/spinner/Spinner';
import { SnackBarContext } from 'contexts/SnackBarContext';

export default function Login({ navigation }: StackScreenProps<RootStackScreenList, 'Login'>) {
  const { setSnackBar } = useContext(SnackBarContext);
  const [email, onChangeEmail] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(true);
  const [password, onChangePassword] = useState('');
  const { setLoggedUser } = useContext(LoggedUserContext);
  const { response: user, error, fetchData, loading } = useAxios<User>({
    url: `${API_URL}/customers/authenticate`,
    method: 'post',
    body: { email, password }
  });

  useEffect(() => {
    if (error && user) {
      setSnackBar({ visible: true, message: 'Usuário ou senha inválidos' });
    }
  }, [error]);
  
  useEffect(() => {
    if (user) {
      AsyncStorage.setItem('estacioMagaCredentials', JSON.stringify(user));
      setLoggedUser({ user });
    } else if (error && error.response?.status === 401) {
      setSnackBar({ visible: true, message: error.response.data as string });
    }
  }, [user, error])

  return (
    <SafeAreaView style={{ height: '100%' }}>
      <ContainerView>
        <InformationView>
          <LogoImage style={{ marginBottom: 20 }} source={require('@assets/icon.png')} />
          <Text size={'xl'} style={{ color: '#ffffff' }}>Estacionamento</Text>
          <Text size={'xxl'} style={{ color: '#ffffff' }}>Magalhães</Text>
        </InformationView>
        <FormView>
          <LoginTextInput
            outlineColor='#d9dce2'
            activeOutlineColor='#bdc0c6'
            mode='outlined'
            onChangeText={onChangeEmail}
            placeholder="E-mail"
            autoCapitalize="none"
            keyboardType="email-address"
            value={email}
          />
          <LoginTextInput
            outlineColor='#d9dce2'
            activeOutlineColor='#bdc0c6'
            mode='outlined'
            onChangeText={onChangePassword}
            value={password}
            autoCapitalize="none"
            placeholder="Senha"
            secureTextEntry={passwordVisible}
            right={<TextInput.Icon
              icon={passwordVisible ? "eye" : "eye-off"}
              onPress={() => setPasswordVisible(!passwordVisible)}/>
            }
          />
          <LoginButton
            disabled={loading}
            onPress={fetchData}
          >
            {loading ?
              <Spinner /> :
              <Text style={{ color: '#ffffff' }}>Login</Text>}
          </LoginButton>
          <CreateAccountButton
            onPress={() => navigation.push('AddUser')}
          >
            {/* {isLoadingGate ?
              <Animated.View style={{ transform: [{ rotate: spin }] }}>
                <FontAwesome5 name='circle-notch' size={20} style={{ color: '#fff' }}></FontAwesome5>
              </Animated.View> :
              <FontAwesome5 name='power-off' size={20} style={{ color: '#fff' }}></FontAwesome5>
            } */}
            <Text style={{ textDecorationLine: 'underline' }}>Criar conta</Text>
          </CreateAccountButton>
        </FormView>
      </ContainerView>
    </SafeAreaView>
  );
}

const ContainerView = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  background: #161616;
`;

const InformationView = styled.View`
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoImage = styled.Image`
  border-width: 2px;
  border-color: #ffffff;
  border-radius: 100px;
  width: 130px;
  height: 130px;
`;

const FormView = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  width: 100%;
  background: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const LoginTextInput = styled(TextInput)`
  background-color: #EDF0F6;
  margin-bottom: 16px;
  width: 100%;
  font-family: Quicksand_400Regular;
`;

const LoginButton = styled.TouchableOpacity`
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 40px;
  background-color: #3f66df;
  border-radius: 5000px;
`;

const CreateAccountButton = styled.TouchableOpacity`
  margin-top: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
`;