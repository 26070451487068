import { Text } from "@core/components";
import styled from "styled-components/native";
import { useAxios } from "@core/hooks";
import { API_URL, GateLog } from "@core/models";
import { useEffect, useState } from "react";
import { SafeAreaView, ScrollView, View } from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';
import { StackScreenProps } from "@react-navigation/stack";
import { RootStackScreenList } from "Routes";
import { format, formatISO, isAfter, subDays } from "date-fns";
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';

type GateLogsScreenProps = StackScreenProps<
  RootStackScreenList,
  'GateLogs'
>;

export default function GateLogs({ navigation }: GateLogsScreenProps) {
  const [currentDay, setCurrentDate] = useState(0);

  const { response: logs, error: logsError, fetchData: getLogs, loading } = useAxios<GateLog[]>({
    url: `${API_URL}/gate-log`,
    method: 'get',
    params: {
      date: `${format(subDays(new Date(), currentDay * -1), 'yyyy-MM-dd')}T00:00:00.000Z`
    }
  });

  useEffect(() => {
    getLogs();
  }, [currentDay]);

  return (
    <SafeAreaView>
      <RequestsView>
        <TitleView>
          <Ionicons name='person-outline' size={24} />
          <Text size='xl' weight={700}>Acessos do portão</Text>
        </TitleView>
      </RequestsView>
      <ScrollView style={{ paddingLeft: 16, paddingRight: 16, maxHeight: 'calc(100vh - 175px)' }}>
        {loading && <Text>Carregando...</Text>}
        {!loading ? logs?.map(log => (
          <RowView id={log._id} key={log._id}>
            <RowInfoView>
              <Text size='xs' weight={400}>{log.email}</Text>
              <Text size='xs'>{format(new Date(log.date), 'HH:mm:ss dd/MM/yyyy')}</Text>
            </RowInfoView>
          </RowView>
        )) : <></>}
        {!logs?.length && !loading ? <Text>
          {`Nenhum dado encontrado para o dia selecionado`}
        </Text> : <></>}
      </ScrollView>
      <PaginationView>
        <ActionButton onPress={() => setCurrentDate(currentDay - 1)}>
          <FontAwesome5 name="arrow-left" color="#ffffff"></FontAwesome5>
        </ActionButton>
        {[3, 2, 1, 0].map((id) => {
          return <DateButton key={id} style={{ borderWidth: !id ? 2 : 1, borderColor: !id ? '#161616' : '#cccccc'}}>
            <Text size="xs">{format(subDays(new Date(), id - currentDay), 'dd/MM')}</Text>
          </DateButton>
        })}
        <ActionButton
          disabled={isAfter(subDays(new Date(), (currentDay + 1) * -1), new Date())}
          style={{ backgroundColor: isAfter(subDays(new Date(), (currentDay + 1) * -1), new Date()) ? '#cccccc' : '#161616' }}
          onPress={() => setCurrentDate(currentDay + 1)}>
          <FontAwesome5 name="arrow-right" color="#ffffff"></FontAwesome5>
        </ActionButton>
      </PaginationView>
    </SafeAreaView>
  )
}

const RequestsView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

const TitleView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
`;

const RowView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: center;
  column-gap: 4px;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-color: #cccccc;
  padding: 8px 0px;
  width: 100%;
`;

const RowInfoView = styled.View`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  flex: 1;
`;

const PaginationView = styled.View`
  background: #f2f2f2;
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px 8px;
  gap: 4px;
`;

const DateButton = styled.TouchableOpacity`
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
`;

const ActionButton = styled.TouchableOpacity`
  background: #161616;
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  color: white;
  border-radius: 4px;
`;