import { Text } from "@core/components"
import { View } from "react-native";
import styled from "styled-components/native";

export default function InfoUser({ email, password }: { email: string, password: string }) {
  return (
    <InfoView>
      <Text weight={700} size='xxl'>A sua conta está registrada!</Text>
      <Text size='lg'>O Alberto irá aceitar a sua requisição em instantes e você será notificado.</Text>
      <Text size='lg'>Para fazer o login, utilize:</Text>
      <View style={{ display: 'flex', flexDirection: 'row', columnGap: 4 }}>
        <Text size="md">E-mail:</Text><Text size="md" weight={700}>{email}</Text>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', columnGap: 4 }}>
        <Text size="md">Senha:</Text><Text size="md" weight={700}>{password}</Text>
      </View>
    </InfoView>
  )
}

const InfoView = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  width: 100%;
  row-gap: 6px;
`;